import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";

function validateRule(rule, value, fieldLabel) {
  if (rule === 'required') {
    return !value ? `${fieldLabel} is a required field` : true;
  }

  if (rule === 'email') {
    return !isEmail(value)
      ? `Please enter a valid email address`
      : true;
  }

  // laravel validation does not support tel
  if (rule === 'tel') {
    return !isMobilePhone(value)
      ? `Please enter a valid phone number`
      : true;
  }

  return true;
}

function validateForm(form) {
  const allErrors = [];
  form.querySelectorAll("[data-form-field]").forEach((el) => {
    if (!el.dataset.formFieldValidate) {
      return;
    }

    if (el.matches('.has-condition:not(.is-passed)')) {
      return;
    }

    const type = el.dataset.formFieldType;
    const rules = el.dataset.formFieldValidate.split(":::");
    let value = null;
    let input;

    switch (type) {
      case "text":
      case "textarea":
      case "integer":
      case "email":
      case "tel":
        input = el.querySelector("[name]");
        value = input ? input.value : null;
        break;
      case "select":
        input = el.querySelector("select");
        value = input ? input.value : null;
        break;
      case "toggle":
      case "radio":
      case "checkboxes":
        value = !!el.querySelector("input:checked");
        break;
      case "assets":
        input = el.querySelector("input");
        value = input ? input.files.length : null;
        break;
    }

    rules.forEach((rule) => {
      const result = validateRule(rule, value, el.dataset.formFieldLabel);
      if (result !== true) {
        allErrors.push(result);
        setFieldErrorMessage(form, el.dataset.formField, result);
      }
    });
  });

  return allErrors;
}

function setFieldErrorMessage(form, fieldHandle, message) {
  const field = form.querySelector(`[data-form-field=${fieldHandle}]`);
  if (field) {
    field.classList.add("has-error");
    const fieldErrorEl = field.querySelector(`[data-error-message]`);
    if (fieldErrorEl) {
      fieldErrorEl.innerHTML = message;
    }
  }
}

function setGlobalErrorMessage(form, message) {
  form.classList.add("has-errors");
  const errorsEl = form.querySelector("[data-all-error-messages]");
  if (errorsEl) {
    errorsEl.innerHTML = message;
  }
}

function clearErrorMessages(form) {
  form.classList.remove("has-errors");
  form.querySelectorAll("[data-form-field]").forEach((el) => {
    el.classList.remove("has-error");
  });
  form.querySelectorAll("[data-error-message]").forEach((el) => {
    el.innerHTML = "";
  });
}

function onSubmit(e) {
  const form = e.target;
  if (!form.matches(".wl-form")) {
    return;
  }
  e.preventDefault();

  if (form.classList.contains("is-loading")) {
    return;
  }

  clearErrorMessages(form);
  const formData = new FormData(form);
  const metaCsrfToken = document.querySelector('meta[name="csrf-token"]');
  formData.set('_token', metaCsrfToken ? metaCsrfToken.getAttribute('content') : 'META_CSRF_TOKEN_NOT_FOUND');

  const validationResult = validateForm(form);

  if (validationResult.length) {
    setGlobalErrorMessage(form, validationResult.map(i => `<div>${i}</div>`).join(''));
    return;
  }

  form.querySelectorAll('.has-condition:not(.is-passed)').forEach(field => {
    formData.delete(field.dataset.formField)
  })

  form.querySelectorAll('[data-form-field-unless].is-passed').forEach(field => {
    formData.delete(field.dataset.formField)
  })

  form.querySelectorAll('[data-form-field-unless-any].is-passed').forEach(field => {
    formData.delete(field.dataset.formField)
  })

  form.classList.add("is-loading");
  fetch(form.action, {
    method: "POST",
    cache: "no-cache",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
    body: formData,
  })
    .then((response) => {
      return new Promise((resolve) =>
        response.json().then((json) =>
          resolve({
            status: response.status,
            ok: response.ok,
            json,
          })
        )
      );
    })
    .then(({json, status}) => {
      switch (status) {
        case 200:
        case 201:
          form.classList.add("is-submitted");
          form.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          const event = new CustomEvent('form:sent', {cancelable: true, bubbles: true});
          form.dispatchEvent(event);
          break;
        default:
          setGlobalErrorMessage(
            form,
            json.errors ? json.errors.join("<br/>") : ""
          );
          if (json.error) {
            for (const [key, value] of Object.entries(json.error)) {
              setFieldErrorMessage(form, key, value);
            }
          }
          break;
      }
    })
    .catch((error) => {
      console.error("Error: ", error);
      setGlobalErrorMessage(form, error);
    })
    .finally(() => {
      form.classList.remove("is-loading");
    });
}

function onChange(e) {
  const form = e.target.closest('.wl-form');
  if (!form) return;

  const conditionalIfFields = form.querySelectorAll('[data-form-field-if]')
  const conditionalIfAnyFields = form.querySelectorAll('[data-form-field-if-any]')
  const conditionalUnlessFields = form.querySelectorAll('[data-form-field-unless]')
  const conditionalUnlessAnyFields = form.querySelectorAll('[data-form-field-unless-any]')
  if (!conditionalIfFields) return;

  const formData = new FormData(form);

  [...conditionalIfFields, ...conditionalIfAnyFields, ...conditionalUnlessFields, ...conditionalUnlessAnyFields].forEach(field => {
    let passedConditions = null
    const conditions = JSON.parse(field.dataset.formFieldIf || field.dataset.formFieldIfAny || field.dataset.formFieldUnless || field.dataset.formFieldUnlessAny)

    for (const key in conditions) {
      const [operator, value] = [conditions[key].slice(0, conditions[key].indexOf(' ')), conditions[key].slice(conditions[key].indexOf(' ') + 1)]
      console.log('value', value)
      switch (operator) {
        case 'equals':
        case '===':
          passedConditions = formData.get(key) === value
          break
        case 'not':
        case '!==':
          passedConditions = formData.get(key) !== value
          break
        case 'contains':
        case 'contains_any':
          passedConditions = formData.get(key).includes(value)
          break
        case '>':
        case '<':
        case '>=':
        case '<=':
          passedConditions = eval(`${formData.get(key)}
          ${operator}
          ${value}`)
          break
      }

      if (
        !passedConditions && [...conditionalIfFields].includes(field) ||
        passedConditions && [...conditionalIfAnyFields].includes(field) ||
        !passedConditions && [...conditionalUnlessFields].includes(field) ||
        passedConditions && [...conditionalUnlessAnyFields].includes(field)
      ) {
        break
      }
    }

    field.classList.toggle('is-passed', passedConditions)
  })
}

window.addEventListener("submit", onSubmit);
window.addEventListener("change", onChange);
