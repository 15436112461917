const selectors = {
  class: '.wl-select',
  isActive: 'is-active',
  isSelected: 'is-selected',
  item: '[data-value]',
}

const activeSelect = {}

const showSelect = () => {
  activeSelect.el.classList.add(selectors.isActive)
}

const hideSelect = () => {
  activeSelect.el.classList.remove(selectors.isActive)
  activeSelect.el = null
}

const initCustomSelect = () => {
  window.addEventListener('click', e => {
    const target = e.target

    if (!target.closest(selectors.class)) {
      if (activeSelect.el) {
        hideSelect()
      }
    } else {
      if (target.matches(selectors.class)) {
        // in case second select opened while the first one was not closed
        if (activeSelect.el) {
          hideSelect()
        }

        activeSelect.el = target
        activeSelect.select = target.querySelector('select')
        activeSelect.isMultiple = activeSelect.select.hasAttribute('multiple')
        activeSelect.value = target.querySelector('div')
        activeSelect.list = target.querySelector('ul')

        target.classList.contains(selectors.isActive) ? hideSelect() : showSelect()
      }
    }

    if (target.matches(selectors.item) && activeSelect.el) {
      if (!activeSelect.isMultiple) {

        const defaultLabelOnOption = activeSelect.el.dataset.defaultLabelOnOption;
        const defaultLabel = activeSelect.el.dataset.defaultLabel;

        activeSelect.select.value = target.dataset.value;

        // we don't want to display "All" and display the default label instead
        if (defaultLabel && defaultLabelOnOption && defaultLabelOnOption === activeSelect.select.value) {
          activeSelect.value.innerHTML = defaultLabel;
        } else {
          activeSelect.value.innerHTML = target.innerHTML;
        }
        [...activeSelect.list.children].forEach(el => el.classList.toggle(selectors.isActive, el === target))
      } else {
        target.classList.toggle(selectors.isActive);
        const values = [...activeSelect.list.children].filter(el => el.classList.contains(selectors.isActive)).map(el => el.dataset.value)
        const options = activeSelect.select.options;

        for (let i = 0; i < options.length; i++) {
          options[i].selected = values.includes(options[i].value);
        }
      }

      activeSelect.select.dispatchEvent(new Event('change', {bubbles: true}));
      activeSelect.el.classList.add(selectors.isSelected)

      hideSelect()
    }
  })

  // to support when select changed outside or via accessibility
  // window.addEventListener('change', e => {
  //   const target = e.target;
  //   const el = target.closest(selectors.class)
  //
  //   if (el) {
  //     const item = el.querySelector(`[data-value="${target.value}"]`)
  //     const itemIsActive = item.classList.contains(selectors.isActive)
  //
  //     if (!itemIsActive) {
  //       const value = el.querySelector(`div`)
  //       const list = el.querySelector(`ul`);
  //
  //       [...list.children].forEach(el => el.classList.toggle(selectors.isActive, el === item))
  //       value.innerHTML = item.innerHTML
  //     }
  //   }
  // })

  return false
}

export default initCustomSelect()


