import {getCookie, setCookie} from "@/utils/cookies.js";

class AnnouncementBar extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    document.body.classList.add('has-announcement')
    this.querySelector('[data-close]').addEventListener('click', this.close.bind(this))
  }

  close() {
    setCookie('announcement', this.attributes.uniqid.value)
    document.body.classList.remove('has-announcement')
  }

  disconnectedCallback() {
  }
}

if (!customElements.get('announcement-bar')) {
  customElements.define('announcement-bar', AnnouncementBar);
}
