window.addEventListener('modal:open', e => {
  const video = e.target.querySelector('[data-modal-video]');
  if (!video) return;

  const src = video.getAttribute('data-modal-video');
  video.src = `${src}?autoplay=1&enablejsapi=1`;
})

window.addEventListener('modal:close', e => {
  const video = e.target.querySelector('[data-modal-video]');
  if (!video) return;

  video.src = video.getAttribute('data-modal-video');
})
