import { isExternalLink } from '../utils/links';

const selectors = {
  active: 'is-shown',
  bodyClass: 'modal-is-shown',
  modalClass: '.modal',
  dataAttr: '[data-modal]',
};

const activeModals = {};

const toggleModal = (id, trigger = null, closedByEsc = false) => {
  if (!id) {
    return;
  }
  const modal = document.querySelector(`#${id}`);
  if (!modal) {
    return;
  }
  const isActive = !!activeModals[id];
  const event = new CustomEvent(isActive ? 'modal:close' : 'modal:open', {
    cancelable: true,
    bubbles: true,
    detail: { trigger, closedByEsc },
  });

  if (modal.dispatchEvent(event)) {
    modal.classList.toggle(selectors.active, !isActive);
    document.body.classList.toggle(`${id}-${selectors.active}`, !isActive);
    trigger && trigger.classList.toggle(selectors.active, !isActive);

    if (isActive) {
      // support nested modals
      Object.keys(activeModals).length === 1 && document.body.classList.remove(selectors.bodyClass);

      activeModals[id].trigger?.focus();
      delete activeModals[id];
    } else {
      document.body.classList.add(selectors.bodyClass);
      modal.focus();
      activeModals[id] = {
        el: modal,
        trigger,
      };
    }
  }
};

const getActiveModals = () => {
  return activeModals;
};

const closeAllModals = () => {
  for (let id in activeModals) {
    toggleModal(id, activeModals[id].trigger);
  }
};

const initModals = () => {
  document.addEventListener('click', (e) => {
    const target = e.target;

    if (isExternalLink(target)) {
      return;
    }

    // handle click outside close active modal
    if (target.matches(selectors.modalClass)) {
      toggleModal(target.id);
    }

    // open/close modal
    if (target.matches(selectors.dataAttr)) {
      toggleModal(target.dataset.modal, target);
    }

    // if modal triggered by hash link
    if (target.hash && document.querySelector(target.hash)?.matches(selectors.modalClass)) {
      e.preventDefault();
      toggleModal(target.hash.substring(1), target);
    }
  });

  const initOnHash = () => {
    try {
      if (location.hash && document.querySelector(location.hash)?.matches(selectors.modalClass)) {
        toggleModal(location.hash.substring(1));
      }
    } catch (err) {}
  };
  initOnHash();

  window.addEventListener('hashchange', initOnHash);

  window.addEventListener('keyup', (e) => {
    if (['Esc', 'Escape'].includes(e.key) && Object.keys(activeModals).length) {
      const lastModal = activeModals[Object.keys(activeModals).pop()];

      toggleModal(lastModal.el.id, lastModal.trigger, true);
    }
  });

  return false;
};

export default initModals();
export { toggleModal, getActiveModals, closeAllModals, initModals };
