window.LOAD_SCRIPT_CACHE = {};

export default function loadScript(url, callback = null) {
  if (window.LOAD_SCRIPT_CACHE[url]) {
    if (callback) {
      callback(url);
    }
    return;
  }

  window.LOAD_SCRIPT_CACHE[url] = true;
  const script = document.createElement("script");
  script.src = url;
  script.async = true;

  if (callback) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback(url);
      }
    };
    script.onload = function () {
      callback(url);
    };
  }

  document.getElementsByTagName("head")[0].appendChild(script);
}
